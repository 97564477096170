import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import DefaultLayout from '../../layouts/DefaultLayout';
import Seo from '../../../app/Seo';
import Page from '../../components/Page';
import Link from '../../components/Link';

export default function ContactPage() {
  const Item = styled('div')(({ theme }) => ({
    ...theme.typography.h6,
    a: {
      textDecoration: `none`,
      color: theme.palette.text.primary,
    },
    'a:visited': { textDecoration: `none` },
    '&:hover': {
      color: theme.palette.text.secondary,
    },
  }));

  return (
    <>
      <Seo title='Contact' />
      <DefaultLayout>
        <Page pageTitle='Contact'>
          <Box sx={{ maxWidth: '500px', textAlign: 'center', margin: 'auto' }}>
            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <AlternateEmailIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText>
                  <Typography variant='h5'>contact@magic.audio</Typography>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <FacebookIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText>
                  <Item>
                    <Link to='https://www.facebook.com/MagicAudioLLC'>
                      https://www.facebook.com/MagicAudioLLC
                    </Link>
                  </Item>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <LinkedInIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText>
                  <Item>
                    <Link to='https://www.linkedin.com/in/tsakostas/'>
                      https://www.linkedin.com/in/tsakostas/
                    </Link>
                  </Item>
                </ListItemText>
              </ListItem>
            </List>
          </Box>
        </Page>
      </DefaultLayout>
    </>
  );
}
